import React from "react";
import { Calendar, Users } from "lucide-react";
import { TripData, TripStatus } from "../types";

interface TripDetailsProps {
  tripData: TripData;
  travelersCount: number;
  tripStatus: TripStatus;
}

const TripDetails = ({
  tripData,
  travelersCount,
  tripStatus,
}: TripDetailsProps) => {
  const statusColors: Record<TripStatus, string> = {
    "Pre-Booking": "bg-yellow-100 text-yellow-800",
    Booked: "bg-blue-100 text-blue-800",
    Paid: "bg-green-100 text-green-800",
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Calendar className="h-6 w-6 text-purple-600" />
          <h2 className="text-2xl font-semibold text-gray-800">Trip Details</h2>
        </div>
        <div className="flex items-center gap-4">
          <span
            className={`px-3 py-1 rounded-full text-sm font-medium ${statusColors[tripStatus]}`}
          >
            {tripStatus}
          </span>
          <button
            onClick={() => {
              const startDate = "20240417";
              const endDate = "20240421";
              window.open(
                `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
                  "Morocco Desert Adventure"
                )}&dates=${startDate}/${endDate}`,
                "_blank"
              );
            }}
            className="inline-flex items-center px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
          >
            Add to Calendar
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex items-center gap-2">
          <Calendar className="h-5 w-5 text-purple-600" />
          <span>
            {tripData.dates.start} - {tripData.dates.end}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <Users className="h-5 w-5 text-purple-600" />
          <span>{travelersCount} Travelers</span>
        </div>
        <div className="flex items-center gap-2">
          <Calendar className="h-5 w-5 text-purple-600" />
          <span>{tripData.dates.duration}</span>
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
