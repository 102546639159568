import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { NavBar } from "./components/NavBar";
import { LoadingState, ErrorState } from "./components/LoadingState";
import TripOverview from "./overview/TripOverview";
import TasksApp from "./tasks/Tasks";
import ChatApp from "./chat/Chat";
import Travelers from "./travelers/Travelers";
import { useTripData } from "./hooks/useTripData";
import { TabId } from "./constants/navigation";

const PortalApp = () => {
  const { tripId } = useParams<{ tripId: string }>();
  const [activeTab, setActiveTab] = useState<TabId>("info");
  const { trip, setTravelers, isLoading, error } = useTripData(tripId ?? "");

  if (!tripId) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center text-red-600 p-6">
          <p>No trip ID provided</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <ErrorState error={error} onRetry={() => window.location.reload()} />
    );
  }

  if (!trip) {
    return (
      <ErrorState
        error={"error fetching data"}
        onRetry={() => window.location.reload()}
      />
    );
  }
  console.log(trip);

  return (
    <div className="min-h-screen bg-gray-50">
      <NavBar activeTab={activeTab} onTabChange={setActiveTab} />

      <div className="max-w-5xl mx-auto p-6">
        {activeTab === "info" && <TripOverview tripData={trip} />}
        {activeTab === "travelers" && (
          <Travelers travelers={trip?.travelers} setTravelers={setTravelers} />
        )}
        {activeTab === "tasks" && <TasksApp />}
        {activeTab === "messages" && <ChatApp />}
      </div>
    </div>
  );
};

export default PortalApp;
