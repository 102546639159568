import React from "react";
import {
  Hotel,
  Clock,
  Info,
  Phone,
  Mail,
  Plane,
  Calendar,
  MapPin,
} from "lucide-react";

interface FlightDetails {
  airline: string;
  flightNumber: string;
  departure: {
    airport: string;
    dateTime: string;
    terminal?: string;
    gate?: string;
  };
  arrival: {
    airport: string;
    dateTime: string;
    terminal?: string;
    gate?: string;
  };
  class: string;
  notes?: string;
}

interface AccommodationDetails {
  name: string;
  type: string;
  checkIn?: string;
  checkOut?: string;
  notes?: string;
  email?: string;
  phone?: string;
}

interface ItineraryDay {
  date: string;
  description: string;
  activities?: string[];
  accommodation?: AccommodationDetails;
  flights?: FlightDetails[];
}

interface ItineraryItemProps {
  day: ItineraryDay;
  showFullDetails: boolean;
}

const ItineraryItem = ({ day, showFullDetails }: ItineraryItemProps) => {
  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      month: "short",
      day: "numeric",
    });
  };

  const renderFlights = (flights: FlightDetails[]) => {
    if (!showFullDetails) {
      return (
        <div className="flex items-center gap-2 mt-2 text-gray-500">
          <Plane className="h-4 w-4" />
          <span>
            {flights.length} flight{flights.length > 1 ? "s" : ""}
          </span>
        </div>
      );
    }

    return (
      <div className="mt-3 space-y-4">
        {flights.map((flight, idx) => (
          <div key={idx} className="bg-blue-50 rounded-md p-3">
            <div className="flex items-center gap-2 text-gray-700 font-medium">
              <Plane className="h-4 w-4" />
              <span>
                {flight.airline} {flight.flightNumber}
              </span>
              <span className="text-gray-400 text-sm">({flight.class})</span>
            </div>

            <div className="mt-2 space-y-2">
              <div className="flex items-start gap-2">
                <MapPin className="h-4 w-4 mt-1 text-blue-600" />
                <div className="flex-1">
                  <div className="text-sm font-medium">Departure</div>
                  <div className="text-sm text-gray-600">
                    {flight.departure.airport}
                  </div>
                  <div className="text-sm text-gray-500">
                    {formatDateTime(flight.departure.dateTime)}
                    {flight.departure.terminal &&
                      ` • Terminal ${flight.departure.terminal}`}
                    {flight.departure.gate &&
                      ` • Gate ${flight.departure.gate}`}
                  </div>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <MapPin className="h-4 w-4 mt-1 text-red-600" />
                <div className="flex-1">
                  <div className="text-sm font-medium">Arrival</div>
                  <div className="text-sm text-gray-600">
                    {flight.arrival.airport}
                  </div>
                  <div className="text-sm text-gray-500">
                    {formatDateTime(flight.arrival.dateTime)}
                    {flight.arrival.terminal &&
                      ` • Terminal ${flight.arrival.terminal}`}
                    {flight.arrival.gate && ` • Gate ${flight.arrival.gate}`}
                  </div>
                </div>
              </div>
            </div>

            {flight.notes && (
              <div className="flex items-start gap-2 mt-2 text-gray-500 text-sm">
                <Info className="h-4 w-4 mt-0.5" />
                <span>{flight.notes}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderAccommodation = (
    accommodation: AccommodationDetails | string
  ) => {
    if (typeof accommodation === "string") {
      return (
        <div className="flex items-center gap-2 mt-2 text-gray-500">
          <Hotel className="h-4 w-4" />
          <span>{accommodation}</span>
        </div>
      );
    }

    if (!showFullDetails) {
      return (
        <div className="flex items-center gap-2 mt-2 text-gray-500">
          <Hotel className="h-4 w-4" />
          <span>{accommodation.name}</span>
        </div>
      );
    }

    return (
      <div className="mt-3 bg-gray-50 rounded-md p-3">
        <div className="flex items-center gap-2 text-gray-700 font-medium">
          <Hotel className="h-4 w-4" />
          <span>{accommodation.name}</span>
          <span className="text-gray-400 text-sm">({accommodation.type})</span>
        </div>

        {(accommodation.checkIn || accommodation.checkOut) && (
          <div className="flex items-center gap-2 mt-2 text-gray-500 text-sm">
            <Clock className="h-4 w-4" />
            <span>
              {accommodation.checkIn && `Check-in: ${accommodation.checkIn}`}
              {accommodation.checkIn && accommodation.checkOut && " | "}
              {accommodation.checkOut && `Check-out: ${accommodation.checkOut}`}
            </span>
          </div>
        )}

        {accommodation.phone && (
          <div className="flex items-center gap-2 mt-2 text-gray-500 text-sm">
            <Phone className="h-4 w-4" />
            <a
              href={`tel:${accommodation.phone}`}
              className="hover:text-blue-600"
            >
              {accommodation.phone}
            </a>
          </div>
        )}

        {accommodation.email && (
          <div className="flex items-center gap-2 mt-2 text-gray-500 text-sm">
            <Mail className="h-4 w-4" />
            <a
              href={`mailto:${accommodation.email}`}
              className="hover:text-blue-600"
            >
              {accommodation.email}
            </a>
          </div>
        )}

        {accommodation.notes && (
          <div className="flex items-start gap-2 mt-2 text-gray-500 text-sm">
            <Info className="h-4 w-4 mt-0.5" />
            <span>{accommodation.notes}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="border-l-4 border-purple-600 pl-4 py-2">
      <div className="flex items-center gap-2">
        <Calendar className="h-5 w-5 text-purple-600" />
        <h3 className="font-semibold text-lg">{day.date}</h3>
      </div>

      {showFullDetails && (
        <p className="text-gray-600 mt-2">{day.description}</p>
      )}

      {showFullDetails && day.activities && (
        <ul className="mt-2 space-y-1">
          {day.activities.map((activity, idx) => (
            <li key={idx} className="text-gray-600 text-sm">
              • {activity}
            </li>
          ))}
        </ul>
      )}

      {day.flights && renderFlights(day.flights)}
      {day.accommodation && renderAccommodation(day.accommodation)}
    </div>
  );
};

export default ItineraryItem;
