import React from "react";
import TripDetails from "./TripDetails";
import Itinerary from "./Itinerary";
import { TripData } from "../types";

interface TripOverviewProps {
  tripData: TripData;
}

const TripOverview: React.FC<TripOverviewProps> = ({ tripData }) => {
  return (
    <div className="space-y-8">
      <h1 className="text-4xl font-bold text-gray-900 text-center">
        {tripData.name}
      </h1>

      <TripDetails
        tripData={tripData}
        travelersCount={tripData.travelers.length}
        tripStatus="Booked"
      />

      <Itinerary itinerary={tripData.itinerary} />
    </div>
  );
};

export default TripOverview;
