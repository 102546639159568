import React from "react";
import { Mail, Phone, Trash2 } from "lucide-react";
import PaymentStatus from "./PaymentStatus";
import PassportStatus from "./PassportStatus";
import InsuranceStatus from "./InsuranceStatus";

const TravelerCard = ({ traveler, expanded, onRemove }) => {
  return (
    <div className="border rounded-lg p-4">
      <div className="flex items-start justify-between gap-3 mb-4">
        <div className="flex-grow">
          <h3 className="text-xl font-semibold">
            {traveler.firstName} {traveler.lastName}
          </h3>
          {traveler.email && (
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Mail className="h-4 w-4" />
              <span>{traveler.email}</span>
            </div>
          )}
          {traveler.phone && (
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <Phone className="h-4 w-4" />
              <span>{traveler.phone}</span>
            </div>
          )}
        </div>
        {onRemove &&
          traveler.status &&
          traveler.status.payment.status == "pending" && (
            <button
              onClick={() => onRemove(traveler.id)}
              className="p-2 text-gray-400 hover:text-red-600 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Trash2 className="h-5 w-5" />
            </button>
          )}
      </div>

      {traveler.status && (
        <div
          className={`
          space-y-2
          ${expanded ? "divide-y divide-gray-100" : ""}
          ${expanded ? "" : "space-x-2"}
        `}
        >
          <PaymentStatus
            payment={traveler.status.payment}
            expanded={expanded}
          />
          <PassportStatus
            passport={traveler.status.passport}
            expanded={expanded}
          />
          <InsuranceStatus
            insurance={traveler.status.insurance}
            expanded={expanded}
          />
        </div>
      )}
    </div>
  );
};

export default TravelerCard;
