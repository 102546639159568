import { useState } from 'react';

const TasksApp = () => {
  const [completedTasks, setCompletedTasks] = useState({});

  const taskSections = [
    {
      title: "Required Documents",
      tasks: [
        "Upload passport (valid for at least 6 months)",
        "Provide travel insurance details",
        "Submit flight details"
      ]
    },
    {
      title: "Payment",
      tasks: [
        "Complete 50% deposit payment",
        "Schedule final payment",
        "Review cancellation policy"
      ]
    }
  ];

  return (
    <div className="space-y-8">
      <div className="bg-blue-50 p-4 rounded-lg">
        <p className="text-blue-700">Complete these tasks to prepare for your trip</p>
      </div>

      {taskSections.map((section, index) => (
        <div key={index} className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">{section.title}</h2>
          <div className="space-y-3">
            {section.tasks.map((task, taskIndex) => (
              <div key={taskIndex} className="flex items-center gap-3 p-3 bg-gray-50 rounded-lg">
                <input
                  type="checkbox"
                  checked={completedTasks[`${index}-${taskIndex}`] || false}
                  onChange={() => setCompletedTasks(prev => ({
                    ...prev,
                    [`${index}-${taskIndex}`]: !prev[`${index}-${taskIndex}`]
                  }))}
                  className="h-5 w-5 text-purple-600 rounded"
                />
                <span className={completedTasks[`${index}-${taskIndex}`] ? 'line-through text-gray-400' : ''}>
                  {task}
                </span>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TasksApp;