import React from "react";

export const LoadingState = () => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <div className="text-center">
      <div className="w-16 h-16 border-4 border-purple-600 border-t-transparent rounded-full animate-spin mx-auto"></div>
      <p className="mt-4 text-gray-600">Loading trip details...</p>
    </div>
  </div>
);

export const ErrorState = ({
  error,
  onRetry,
}: {
  error: string;
  onRetry: () => void;
}) => (
  <div className="min-h-screen bg-gray-50 flex items-center justify-center">
    <div className="text-center text-red-600 p-6 max-w-md mx-auto">
      <h3 className="text-lg font-semibold mb-2">Error Loading Trip</h3>
      <p className="text-gray-600">{error}</p>
      <button
        onClick={onRetry}
        className="mt-4 px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
      >
        Try Again
      </button>
    </div>
  </div>
);
