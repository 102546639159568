import { Info, ListTodo, MessageSquare, Users } from "lucide-react";

export const TABS = [
  { id: "info", label: "Trip Details", icon: Info },
  { id: "travelers", label: "Travelers", icon: Users },
  { id: "tasks", label: "Preparation", icon: ListTodo },
  { id: "messages", label: "Travel Agent Chat", icon: MessageSquare },
] as const;

export type TabId = (typeof TABS)[number]["id"];
