import React from "react";

const StatusBadge = ({
  status,
  text,
  className = "",
}: {
  status: string;
  text?: string;
  className?: string;
}) => {
  const getColor = () => {
    switch (status) {
      case "completed":
      case "verified":
      case "active":
      case "uploaded":
        return "bg-green-100 text-green-800";
      case "partial":
        return "bg-yellow-100 text-yellow-800";

      default:
        return "bg-red-100 text-red-800";
    }
  };

  return (
    <span
      className={`inline-flex items-center px-3 py-0.5 rounded-full text-xs font-semibold ${getColor()} ${className} uppercase`}
    >
      {text || (status === "missing" ? "Pending" : status)}
    </span>
  );
};

export default StatusBadge;
