import React from "react";
import { CreditCard, ExternalLink } from "lucide-react";
import StatusBadge from "../components/StatusBadge";
import DetailField from "../components/DetailField";
import { TravelerStatus } from "../types";

const PaymentStatus = ({
  payment,
  expanded,
}: {
  payment: TravelerStatus["payment"];
  expanded: boolean;
}) => {
  const summary = (
    <StatusBadge status={payment.status} text={`${payment.status} Payment`} />
  );
  if (!expanded) return summary;

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <CreditCard className="h-5 w-5 text-gray-500" />
          <span className="font-medium">Payment Status</span>
          <StatusBadge status={payment.status} />
        </div>
        {payment.status !== "completed" && payment.paymentLink && (
          <a
            href={payment.paymentLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-4 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
          >
            Make Payment
            <ExternalLink className="h-4 w-4" />
          </a>
        )}
      </div>
      <DetailField label="Total Amount" value={`$${payment.totalAmount}`} />
      <DetailField label="Paid Amount" value={`$${payment.paidAmount}`} />
      {payment.pendingAmount && payment.pendingAmount > 0 && (
        <DetailField
          label="Pending Amount"
          value={<span className="text-red-600">${payment.pendingAmount}</span>}
        />
      )}
    </div>
  );
};

export default PaymentStatus;
