import React from "react";
import { FileText, Upload } from "lucide-react";
import StatusBadge from "../components/StatusBadge";
import DetailField from "../components/DetailField";
import { TravelerStatus } from "../types";

const PassportStatus = ({
  passport,
  expanded,
}: {
  passport: TravelerStatus["passport"];
  expanded: boolean;
}) => {
  const summary = (
    <StatusBadge
      status={passport.status}
      text={`Passport ${passport.status}`}
    />
  );
  if (!expanded) return summary;

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center gap-2 mb-4">
        <FileText className="h-5 w-5 text-gray-500" />
        <span className="font-medium">Passport Status</span>
        <StatusBadge status={passport.status} />
      </div>

      {passport.status === "pending" && (
        <div className="mt-4 mb-4">
          <div className="p-4 border border-dashed border-gray-300 rounded-lg bg-white">
            <div className="flex flex-col items-center justify-center text-center">
              <Upload className="h-8 w-8 text-gray-400 mb-2" />
              <p className="text-sm text-gray-600 mb-2">
                Upload a clear copy of your passport
              </p>
              <p className="text-xs text-gray-500 mb-4">
                Accepted formats: JPG, PNG, PDF (Max size: 5MB)
              </p>
              <button
                onClick={() => {}}
                className="inline-flex items-center gap-2 px-4 py-1 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
              >
                <Upload className="h-4 w-4" />
                Upload Passport
              </button>
            </div>
          </div>
        </div>
      )}

      {passport.lastUpdated && (
        <DetailField label="Last Updated" value={passport.lastUpdated} />
      )}
      {passport.expiryDate && (
        <DetailField label="Expiry Date" value={passport.expiryDate} />
      )}
    </div>
  );
};

export default PassportStatus;
