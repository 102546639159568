import React from "react";

const DetailField = ({
  label,
  value,
  className = "",
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
}) => (
  <div className={`flex justify-between items-center ${className}`}>
    <span className="text-sm text-gray-600">{label}:</span>
    <span className="text-sm font-medium">{value}</span>
  </div>
);

export default DetailField;
