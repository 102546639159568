import React, { useState } from "react";
import { ItineraryDay } from "../types";
import ItineraryItem from "./ItineraryItem";
import { ChevronDown, ChevronUp } from "lucide-react";

interface ItineraryProps {
  itinerary: ItineraryDay[];
}

const Itinerary = ({ itinerary = [] }: ItineraryProps) => {
  const [showFullDetails, setShowFullDetails] = useState(true);

  if (!itinerary || itinerary.length === 0) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-2xl font-semibold mb-6">Itinerary</h2>
        <p className="text-gray-500">No itinerary data available.</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Itinerary</h2>
        <button
          onClick={() => setShowFullDetails(!showFullDetails)}
          className="inline-flex items-center gap-2 px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 transition-colors"
        >
          {showFullDetails ? (
            <>
              <ChevronUp className="h-4 w-4" />
              Overview
            </>
          ) : (
            <>
              <ChevronDown className="h-4 w-4" />
              Full Details
            </>
          )}
        </button>
      </div>
      <div className="space-y-6">
        {itinerary.map((day, index) => (
          <ItineraryItem
            key={index}
            day={day}
            showFullDetails={showFullDetails}
          />
        ))}
      </div>
    </div>
  );
};

export default Itinerary;
