import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PortalApp from "./Portal";

export const RouterApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/trip/:tripId" element={<PortalApp />} />
        <Route path="/" element={<Navigate to={`/trip/1`} />} />
      </Routes>
    </BrowserRouter>
  );
};
