import React, { useEffect, useState } from "react";
import type { Traveler, TripData } from "../types";

interface UseTripDataReturn {
  trip: TripData | undefined;
  setTravelers: React.Dispatch<React.SetStateAction<Traveler[]>>;
  isLoading: boolean;
  error: string | null;
}

// You might want to put this in an environment variable or config file
const API_BASE_URL = "http://localhost:8081";

export const useTripData = (tripId: string): UseTripDataReturn => {
  const [trip, setTrip] = useState<TripData>();
  const [travelers, setTravelers] = useState<Traveler[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTripData = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetch(`${API_BASE_URL}/trip/${tripId}`, {
          // These headers are needed for CORS
          headers: {
            "Content-Type": "application/json",
            // Add any other required headers (auth tokens, etc.)
          },
          // Needed for CORS if you're using credentials/cookies
          //   credentials: "include", // or 'same-origin' depending on your needs
        });

        if (!response.ok) {
          throw new Error("Failed to fetch trip data");
        }

        const data: TripData = await response.json();
        setTrip(data);
        setTravelers(data.travelers);
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "An error occurred while fetching data"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (tripId) {
      fetchTripData();
    }
  }, [tripId]);

  return { trip, setTravelers, isLoading, error };
};
