import React, { useState } from "react";
import { Users, ChevronUp, ChevronDown, UserPlus } from "lucide-react";
import { Traveler } from "../types";
import TravelerCard from "./TravelerCard";

interface TravelersProps {
  travelers: Traveler[] | undefined;
  setTravelers: React.Dispatch<React.SetStateAction<Traveler[]>>;
}

const Travelers = ({ travelers, setTravelers }: TravelersProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isAddingTraveler, setIsAddingTraveler] = useState(false);
  const [newTraveler, setNewTraveler] = useState<Omit<Traveler, "id">>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: {
      payment: {
        status: "pending",
        totalAmount: 2500,
        paidAmount: 0,
      },
      passport: {
        status: "pending",
      },
      insurance: {
        status: "missing",
      },
    },
  });

  const handleAddTraveler = (e: React.FormEvent) => {
    e.preventDefault();
    if (travelers && newTraveler.firstName && newTraveler.lastName) {
      setTravelers([
        ...travelers,
        { ...newTraveler, id: Date.now().toString() },
      ]);
      setNewTraveler({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        status: {
          payment: {
            status: "pending",
            totalAmount: 2500,
            paidAmount: 0,
          },
          passport: {
            status: "pending",
          },
          insurance: {
            status: "missing",
          },
        },
      });
      setIsAddingTraveler(false);
    }
  };

  const handleRemoveTraveler = (id: string | number) => {
    if (travelers) {
      setTravelers(travelers.filter((t) => t.id !== id));
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <Users className="h-6 w-6 text-purple-600" />
          <h2 className="text-2xl font-semibold">
            Travelers ({travelers?.length ?? 0})
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <button
            onClick={() => setExpanded(!expanded)}
            className="px-3 py-2 text-sm text-gray-600 hover:text-gray-900 rounded-md hover:bg-gray-100 focus:outline-none inline-flex items-center"
          >
            {expanded ? (
              <>
                <ChevronUp className="h-5 w-5 mr-1" />
                <span>Show Less</span>
              </>
            ) : (
              <>
                <ChevronDown className="h-5 w-5 mr-1" />
                <span>Show Details</span>
              </>
            )}
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {travelers &&
          travelers.map((traveler) => (
            <TravelerCard
              key={traveler.id}
              traveler={traveler}
              expanded={expanded}
              onRemove={handleRemoveTraveler}
            />
          ))}

        {isAddingTraveler && (
          <form
            onSubmit={handleAddTraveler}
            className="border rounded-lg p-4 space-y-4"
          >
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="First Name"
                value={newTraveler.firstName}
                onChange={(e) =>
                  setNewTraveler({ ...newTraveler, firstName: e.target.value })
                }
                className="border rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                value={newTraveler.lastName}
                onChange={(e) =>
                  setNewTraveler({ ...newTraveler, lastName: e.target.value })
                }
                className="border rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={newTraveler.email}
                onChange={(e) =>
                  setNewTraveler({ ...newTraveler, email: e.target.value })
                }
                className="border rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
              <input
                type="tel"
                placeholder="Phone"
                value={newTraveler.phone}
                onChange={(e) =>
                  setNewTraveler({ ...newTraveler, phone: e.target.value })
                }
                className="border rounded-md p-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setIsAddingTraveler(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                Add Traveler
              </button>
            </div>
          </form>
        )}

        <button
          onClick={() => setIsAddingTraveler(true)}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 inline-flex items-center gap-1"
        >
          <UserPlus className="h-4 w-4" />
          Add Traveler
        </button>
      </div>
    </div>
  );
};

export default Travelers;
