import React from "react";
import { TABS, TabId } from "../constants/navigation";

interface NavBarProps {
  activeTab: TabId;
  onTabChange: (tab: TabId) => void;
}

export const NavBar = ({ activeTab, onTabChange }: NavBarProps) => (
  <div className="bg-white border-b sticky top-0 z-10">
    <div className="max-w-5xl mx-auto">
      <div className="flex justify-center space-x-8">
        {TABS.map(({ id, label, icon: Icon }) => (
          <button
            key={id}
            onClick={() => onTabChange(id)}
            className={`px-4 py-4 flex items-center gap-2 border-b-2 transition-colors ${
              activeTab === id
                ? "border-purple-600 text-purple-600 font-medium"
                : "border-transparent text-gray-500 hover:text-gray-700"
            }`}
          >
            <Icon className="h-5 w-5" />
            <span>{label}</span>
          </button>
        ))}
      </div>
    </div>
  </div>
);
