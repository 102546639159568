import React from "react";
import { Shield, ExternalLink } from "lucide-react";
import StatusBadge from "../components/StatusBadge";
import DetailField from "../components/DetailField";
import { TravelerStatus } from "../types";

const InsuranceStatus = ({
  insurance,
  expanded,
}: {
  insurance: TravelerStatus["insurance"];
  expanded: boolean;
}) => {
  const summary = (
    <StatusBadge status={insurance.status} text="Insurance Ready" />
  );
  if (!expanded) return summary;

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center gap-2 mb-4">
        <Shield className="h-5 w-5 text-gray-500" />
        <span className="font-medium">Insurance Details</span>
        <StatusBadge status={insurance.status} />
      </div>

      <div className="space-y-2">
        <DetailField
          label="Coverage Period"
          value={`${insurance.startDate} - ${insurance.endDate}`}
        />
        {insurance.provider && (
          <DetailField label="Provider" value={insurance.provider} />
        )}
        {insurance.policyNumber && (
          <DetailField label="Policy Number" value={insurance.policyNumber} />
        )}
        {insurance.policyFileUrl && (
          <DetailField
            label="Policy File"
            value={
              <a
                href={insurance.policyFileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center gap-2 text-purple-600 hover:text-purple-700"
              >
                View Insurance Policy PDF
                <ExternalLink className="h-3 w-3" />
              </a>
            }
          />
        )}
        {insurance.contactPhone && (
          <DetailField
            label="Contact"
            value={
              <a
                href={`tel:${insurance.contactPhone}`}
                className="text-purple-600 hover:text-purple-700"
              >
                {insurance.contactPhone}
              </a>
            }
          />
        )}
      </div>
    </div>
  );
};

export default InsuranceStatus;
