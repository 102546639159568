import { useState, useEffect, useRef } from 'react';
import { MessageSquare, Send } from 'lucide-react';

const ChatApp = () => {
  const [messages, setMessages] = useState([{
    id: 1,
    text: "Welcome! I'm here to help with any questions. Feel free to ask about the itinerary, preparations, or local customs.",
    sender: 'Travel AI',
    timestamp: new Date(Date.now() - 172800000),
  }]);
  const [newMessage, setNewMessage] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSimulating, setIsSimulating] = useState(false);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  // Existing conversation simulation code remains the same

  // Predefined Q&A conversation flow
  const simulatedConversation = [
    {
      text: "Can you tell me about the itinerary for April 17th?",
      response: "On April 17th, you'll journey from Marrakech through the Atlas Mountains to Ouarzazate. The day includes crossing the Tizi n'Tichka Pass, visiting the UNESCO World Heritage site Ksar Ait Benhaddou, and touring the Atlas Film Studios. You'll stay at the luxurious Ksar Ighnda, a traditional Moroccan riad with modern amenities, including a pool and spa. Check-in is at 14:00, and you can reach them at +212 5248-88888 if needed."
    },
    {
      text: "What's included in the Desert Luxury Camp experience?",
      response: "The Desert Luxury Camp offers a unique overnight experience in a private en-suite tent with traditional Berber decoration and modern comforts. You'll arrive there after a day visiting the Hassilabied Oasis and enjoying a traditional Gnawa music performance. The highlight is a magical sunset camel trek into the dunes. The camp provides a perfect blend of authenticity and comfort while experiencing the Sahara Desert."
    },
    {
      text: "How long is the entire trip and what are the key locations we'll visit?",
      response: "The trip runs for 5 days and 4 nights, from April 17 to April 21, 2024. You'll experience an amazing journey through Morocco's highlights including Marrakech, the Atlas Mountains, Ksar Ait Benhaddou, Ouarzazate, Todra Gorges, Erg Chebbi dunes, Hassilabied Oasis, Rissani market, and the Draa Valley. Each location offers unique experiences, from UNESCO sites to desert adventures."
    },
    {
      text: "What type of accommodations are provided during the trip?",
      response: "You'll experience a diverse range of high-quality accommodations throughout the journey. These include the luxury Ksar Ighnda Riad, the Merzouga Luxury Desert Camp with panoramic desert views, a night in a private deluxe desert tent at the Desert Luxury Camp, and the 5-star Berbere Palace Hotel with swimming pool and hammam. All accommodations have been carefully selected to provide comfort while maintaining authentic Moroccan charm."
    },
    {
      text: "Could you tell me about the payment and insurance requirements?",
      response: "For payment and insurance, we require full payment before the trip. Looking at our current bookings, some travelers have opted for partial payments with a payment plan available through our Stripe payment system. Insurance is mandatory for all travelers - we work with Global Insurance Co, who provide comprehensive coverage for the duration of the trip. The policy includes coverage from the start date to end date of your journey, and they're reachable 24/7 at +1 888 123 4567 for any emergencies."
    },
    {
      text: "What are the layover times between flights for the journey?",
      response: "For the flight to Marrakech on April 16, there is a 2-hour layover in Casablanca (CMN) between arriving on Air Canada flight AC890 at 07:00 and departing on Royal Air Maroc flight AT401 at 09:00. For the return flight on April 22, there is also a 2-hour layover in Casablanca between arriving on Royal Air Maroc flight AT402 at 08:30 and departing on Air Canada flight AC891 at 10:30."
    }
];

// const simulatedConversation = [
//   {
//       text: "Hi there, I’m interested in exploring a trip to Morocco. Can you tell me what kind of experiences you offer?",
//       response: "Absolutely! We offer immersive trips that showcase the best of Morocco, including camel rides in the Sahara, exploring UNESCO heritage sites like Ksar Ait Benhaddou, trekking through the Atlas Mountains, and visiting vibrant souks in Marrakech. Our trips blend luxury and cultural authenticity."
//   },
//   {
//       text: "That sounds amazing! How long are the trips typically?",
//       response: "Our most popular itinerary runs for 5 days and 4 nights, but we can customize trips to fit your schedule. For example, you could extend your stay to explore Essaouira or Chefchaouen if you'd like."
//   },
//   {
//       text: "What kind of accommodations do you provide?",
//       response: "We provide high-quality accommodations, such as luxury riads in Marrakech, deluxe desert tents in the Sahara, and 5-star hotels like the Berbere Palace. These are carefully selected to offer comfort and an authentic Moroccan experience."
//   },
//   {
//       text: "I’m particularly interested in the desert experience. Can you tell me more about it?",
//       response: "Of course! The desert experience includes a sunset camel trek into the Erg Chebbi dunes, an overnight stay in a private en-suite tent with Berber-inspired decor, and a traditional dinner under the stars. It's a once-in-a-lifetime experience."
//   },
//   {
//       text: "This sounds like something I’d really enjoy. How does the booking process work?",
//       response: "Great to hear that! We can start by collecting your details, and we'll send you an email with all the information. From there, you can finalize the booking, customize the itinerary, and even invite others to join your private trip. Could I have your first name, last name, email, and phone number?"
//   },
//   {
//       text: "Sure, my name is Alex Johnson, and my email is alex.johnson@example.com. My phone number is +1 234 567 8900.",
//       response: "Thank you, Alex! We'll send you an email shortly with all the details. Is there anything else you'd like to know in the meantime?"
//   },
//   {
//       text: "No, that’s all for now. Thanks for your help!",
//       response: "You’re welcome, Alex! Here's a quick summary of our conversation: you're interested in a 5-day trip to Morocco, particularly the desert experience. We'll send an email to alex.johnson@example.com with detailed information, booking options, and ways to customize your trip or invite others. If you have any further questions, feel free to reach out. We look forward to helping you plan an unforgettable journey!"
//   }
// ];
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Auto-resize textarea
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`; // Max height of 150px
    }
  }, [newMessage]);

  const simulateTyping = async (text, setter) => {
    let currentText = '';
    for (let char of text) {
      currentText += char;
      setter(currentText);
      await new Promise(resolve => setTimeout(resolve, Math.random() * 100 + 50));
    }
  };

  const simulateConversation = async () => {
    if (isSimulating) return;
    setIsSimulating(true);

    for (const qa of simulatedConversation) {
      await simulateTyping(qa.text, setNewMessage);

      const userMessage = {
        id: Date.now(),
        text: qa.text,
        sender: 'guest',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, userMessage]);
      setNewMessage('');

      setIsProcessing(true);
      await new Promise(resolve => setTimeout(resolve, 1000));

      const agentMessage = {
        id: Date.now() + 1,
        text: qa.response,
        sender: 'support',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, agentMessage]);
      setIsProcessing(false);

      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    setIsSimulating(false);
  };

  const handleInputClick = () => {
    if (!isSimulating && messages.length <= 1) {
      simulateConversation();
    }
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const messageText = newMessage.trim();
    if (messageText && !isProcessing && !isSimulating) {
      const userMessage = {
        id: Date.now(),
        text: messageText,
        sender: 'guest',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, userMessage]);
      setNewMessage('');
      simulateResponse();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const simulateResponse = () => {
    setIsProcessing(true);
    const responseTime = Math.random() * (3000 - 1500) + 1500;

    setTimeout(() => {
      const response = "Thank you for your question! Please note that this is a demo chat interface. For real assistance, please contact our support team.";
      const newMessage = {
        id: Date.now(),
        text: response,
        sender: 'support',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, newMessage]);
      setIsProcessing(false);
    }, responseTime);
  };

  const formatTimestamp = (date) => {
    const diff = Date.now() - date;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (minutes > 0) return `${minutes} minutes ago`;
    return 'Just now';
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 bg-white flex flex-col">
        <div className="p-4 border-b">
          <div className="flex items-center gap-3">
            <MessageSquare className="h-6 w-6 text-purple-600" />
            <h2 className="text-2xl font-semibold">Travel Agent Chat</h2>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {messages.map(message => (
            <div
              key={message.id}
              className={`p-4 rounded-lg ${
                message.sender === 'guest'
                  ? 'bg-purple-50 ml-12'
                  : 'bg-gray-50 mr-12'
              }`}
            >
              <div className="flex items-start gap-4">
                <div className="flex-1">
                  <p className="font-medium">
                    {message.sender === 'guest' ? 'You' : 'Travel AI'}
                  </p>
                  <p className="text-gray-600 mt-1">{message.text}</p>
                  <p className="text-sm text-gray-400 mt-2">
                    {formatTimestamp(message.timestamp)}
                  </p>
                </div>
              </div>
            </div>
          ))}
          {isProcessing && (
            <div className="bg-gray-50 mr-12 p-4 rounded-lg">
              <div className="flex items-center gap-2">
                <div className="flex space-x-1">
                  <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '0s' }}></div>
                  <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
                  <div className="w-2 h-2 bg-purple-600 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
                </div>
                <span className="text-sm text-gray-500">Support is typing...</span>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        <div className="border-t p-4 bg-white">
          <form onSubmit={handleSendMessage} className="flex gap-2">
            <textarea
              ref={textareaRef}
              value={newMessage}
              onChange={e => setNewMessage(e.target.value)}
              onClick={handleInputClick}
              onKeyDown={handleKeyDown}
              placeholder="Click here to start demo conversation..."
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 resize-none min-h-[40px] max-h-[150px]"
              disabled={isProcessing || isSimulating}
              rows={1}
            />
            <button
              type="submit"
              className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed self-end"
              disabled={!newMessage.trim() || isProcessing || isSimulating}
            >
              <Send className="h-5 w-5" />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChatApp;